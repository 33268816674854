import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { SearchPlacesAPI, GetPlaceAPI } from "../../API";
import PinIcon from "../../assets/SVG/PinIcon";
import { IconButton } from "@mui/material";

function PlacesSearch(props) {
  const size = props.size;
  const [search,setSearch] = useState(props.searched || "");
  const setPlaceFilter = props.setPlaceFilter;
  const placeFilter = props.placeFilter;
  const [placeName,setPlaceName] = useState();
  const [currentOptions, setCurrentOptions] = useState([]);
  const [items, setItems] = useState([]); // Items para el autocomplete
  const [currentWritten, setCurrentWritten] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    if(placeFilter !== -1 ){
          GetPlaceAPI(placeFilter).then((res_place) => {setPlaceName(res_place.city +  "," + res_place.country)});
    }
  }, [placeFilter]);



  const handleOnSearch = async (string) => {
    try {
      const response = await SearchPlacesAPI(string); // Asume que SearchPlacesAPI devuelve una lista de objetos
      console.log(response.results)
      const formattedItems = response.results.map((place, index) => ({
        id: place.id, // Asegurarse de que cada item tenga un ID único
        name: place.city + "," + place.country ,
        value: place.city + "," + place.country , // Si necesitas otro valor para buscar
      }));
          // Agregar un objeto con id: 0, name: string y value: string a filteredOptions
      if (string.trim() !== "") {
        formattedItems.unshift({ id: 0, name: string, value: string });
      }

      setCurrentOptions(formattedItems);
      setCurrentWritten(string.toLowerCase());
    } catch (error) {
      console.error("Error fetching places:", error);
    }
  };

  const handleOnHover = (result) => {
    console.log(result); // el elemento sobre el que se pasa el ratón
  };

  const handleOnSelect = (item) => {
    setPlaceFilter(item.id)
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}> <PinIcon/>{item.name}</span>
      </>
    );
  };

  return (
    <Form onSubmit={() => navigate("/search/" + currentWritten)}>
      <div className="SearchWrapper" style={{ width: size }}>
        <ReactSearchAutocomplete
          items={currentOptions}
          inputSearchString={search}
          onSearch={handleOnSearch}
          showNoResults={false}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          autoFocus
          formatResult={formatResult}
          placeholder="Buscar lugares"
        />
      </div>
      {placeName &&  <div className="CardInfoPlace"><PinIcon/>
      {" "+ placeName}
      
      </div>
    }
    </Form>
  );
}

export default PlacesSearch;
