import React, { useRef, useEffect, useState } from 'react';


const ProgressBar = ({ y1 }) => {
  // Estos son los puntos x marcados en la barra
  const x1 = 100, x2 = 300, x3 = 650, x4 = 1000;

  // Limitar y1 para que no exceda los límites de la barra (0 - 1000)
  const progress = Math.min(Math.max(y1, 0), 1000);

  const myRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (myRef.current) {
      setWidth(myRef.current.getBoundingClientRect().width);
    }
   
  }, []);

  console.log(width)
  console.log(progress/1200 * width)
  console.log(`${Math.round(progress/1050 * width)}px`)

  return (
    <div className="progress-container" ref={myRef}>     
      <div className="markers">
        <div style={{left:"0%"}}>
          <div  className="RewardsProgress" style={{width: `${Math.round(progress/1050 * width)}px`}}/>
        </div>
        <div style={{ left: `${(x1 / 1050) * 100}%` }}>
        <div className='markerdots'/>
        </div>
        <div style={{ left: `${(x2 / 1050) * 100}%` }}>
        <div className='markerdots'/>
        </div>
        <div style={{ left: `${(x3 / 1050) * 100}%` }}>
        <div className='markerdots'/>
        </div>
        <div style={{ left: `${(x4 / 1050) * 100}%` }}>
        <div className='markerdots'/>
        </div>
        <div className='RewardsText' style={{ left: `${(x1 / 1050) * 100}%` }}>
          <span>
            Rising Star
          </span>
          <span>
            100 ptos
          </span>
        </div>
        <div className='RewardsText' style={{ left: `${(x2 / 1050) * 100}%` }}>
          <span>
            Trend Setter
          </span>
          <span>
            300 ptos
          </span>
        </div>
        <div className='RewardsText' style={{ left: `${(x3 / 1050) * 100}%` }}>
          <span>
            Style Icon
          </span>
          <span>
            650 ptos
          </span>
        </div>
        <div className='RewardsText' style={{ left: `${(x4 / 1050) * 100}%` }}>
          <span>
            SuperCranberry
          </span>
          <span>
            1000 ptos
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
