import { useState, useRef, useEffect } from "react";
import React from "react";
import CranMagEmbed from "../Components/CranMagEmbed";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/SVG/SearchIcon";
import ProductBox from "../Components/ProductBox";
import ProductModal from "../Components/ProductModal";
import CloseIcon from "../../assets/SVG/CloseIcon";
import { ProductsAPI } from "../../API";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import SearchAutocomplete from "../Components/SearchAutoComplete";



export default function SearchBar(props) {
  let bannerSize  = window.innerWidth -32;
  if (window.innerWidth > 650){
    bannerSize = 650 -32;
  }
  const [search, setSearch] = useState("");
  const [products,setProducts] = useState(null);
  let productBox_size = Math.round(window.innerWidth/2) - 18 -12 - 16  
  if (productBox_size > 150){
    productBox_size = 150 ;
  } 

  let navigate = useNavigate();


 


  const handleEnter = (event) => {
    if (event.key === "Enter") {
      navigate('/search/'+search)
    }

  };


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        ProductsAPI().then(res => {setProducts(res.results)})
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if(!products){
    fetchData()
    }
  }, [products]);

  return(
    <div className="SearchLayout">
      <ScrollToTopOnMount />
      <div className="HeaderSpacer" style={{height:"56px", width:"30px"}} />
        <div className="SearchBox">
          <SearchAutocomplete size={bannerSize} />
          <div className="SearchSection">
            {/* <div className="BannerSectionTitle">Descubre lo último de nuestra Cranberry MAG</div> */}
              <CranMagEmbed size={bannerSize} />
          </div>
          <div  className="Divider" />
          <div className="ProductsTrending">
          ¡Trending en distintos Wishlists!
            <div className="ProductosFeed">
            <div className="ProductsContainer"> 
            <div className="ProductsBox">
              {products
                ? products.map((producto, index) => {
                    return (
                      <button onClick={() => navigate("../../product/"+ producto.id)} className="ProductButtonBox" >
                      <ProductBox key={index} product={producto} i={producto.id} size={productBox_size} />
                      </button>
                    );
                  })
                : " "}
            </div>
            </div>
            </div>
            <div className="HeaderSpacer" style={{height:"70px", width:"30px"}} />
          </div>
        </div>
    </div>
  )
}


  