import React from "react";


function Counter(props) {
    const { start_time, delta_time } = props;
    const [timeLeft, setTimeLeft] = React.useState({ days: 0, hours: 0, minutes: 0 });
  
    React.useEffect(() => {
      const calculateValue = () => {
        const timeLeft = calculateTimeRemaining(start_time, delta_time);
        setTimeLeft(timeLeft);
      };
  
      calculateValue();
  
      const interval = setInterval(() => {
        calculateValue();
      }, 60000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  
    const calculateTimeRemaining = (start_time, delta) => {
      const [deltaDays, deltaHours, deltaMinutes] = delta.split(':').map((value) => parseInt(value, 10));
      const startDate = new Date(start_time);
      const deadline = new Date(startDate);
  
      deadline.setDate(deadline.getDate() + deltaDays);
      deadline.setHours(deadline.getHours() + deltaHours);
      deadline.setMinutes(deadline.getMinutes() + deltaMinutes);
  
      const now = new Date();
      const timeRemaining = deadline.getTime() - now.getTime();
  
      const remainingDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const remainingHours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const remainingMinutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  
      return {
        days: remainingDays > 0 ? remainingDays : 0  ,
        hours: remainingHours > 0 ? remainingHours : 0 ,
        minutes: remainingMinutes > 0 ? remainingMinutes : 0 ,
      };
    };
  
    return (
      <div className="CounterBox">
        <div className="CounterTitle">{timeLeft.hours < 10 && timeLeft.hours >= 0  ? <span className="CounterWarningText"> {`¡Quedan ${timeLeft.hours} horas!`} </span>
        : "Tiempo Restante" }</div>
        <div className="CounterClock">
          <div className="NumberCounter">
            {timeLeft.days < 10  ? "0" + timeLeft.days : timeLeft.days}
            <div className="CounterSmallText">DÍAS</div>
          </div>
          <div className="NumberCounter">
            {timeLeft.hours < 10 ? "0" + timeLeft.hours : timeLeft.hours}
            <div className="CounterSmallText">HRS</div>
          </div>
          <div className="NumberCounter">
            {timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes}
            <div className="CounterSmallText">MIN</div>
          </div>
        </div>
      </div>
    );
  }


  export default Counter;