// LIBRARY IMPORTS
import React, { useEffect, useState, useRef } from "react";
import { ProductsAPI,PostAPI, GetPlaceAPI } from "../../API";
import { useNavigate, useParams } from "react-router-dom";
import { goBack } from "../../Utils";

// MY CODE IMPORTS


import RecomendationMenu from "../Components/RecomendationMenuV3";
import ShareModal from "../Components/ShareModal";
import CarouselContent from "../Components/CarouselContent";
import WishlistModal from "../Components/WishlistModal";
import FollowButton from "../Components/FollowButton";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import TrashModal from "../Components/TrashModal";


// My icons
import BackIcon from "../../assets/SVG/BackIcon";
import PinIcon from "../../assets/SVG/PinIcon";
import StartIcon from "../../assets/SVG/StarIcon";
import EditIcon from "../../assets/SVG/EditIcon"

function FullPostScreen(props) {
  const [data, setData] = useState();
  const [place, setPlace] = useState();
  const [products, setProducts] = useState();
  const { id } = useParams();
  const [me, setMe] = useState();
  const [showMore,setShowMore] =React.useState(false);
  const [overflowActive, setOverflowActive] = useState(null);
  const textRef = useRef();

  const navigate = useNavigate();

  let isMobile;
  let box_size;
  let image_size;
  const { innerWidth } = window;


  const handleGoBack = () => {
    goBack(navigate)
};



  useEffect(() => {
    if(!me){
        setMe(JSON.parse(localStorage.getItem("me")));
      }
  }, [me]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    //logic for showing popup warning on page refresh
    window.onbeforeunload = function () {
      console.log("BACK BUTTON")
    };
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    handleGoBack();
    
  }


  isMobile = true;
  box_size = innerWidth - 16;
  if (box_size > 500){
    box_size = 500;
  }
  image_size = box_size - 102;


  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };


  useEffect(() => {
    PostAPI(id).then((res) => {
      if (res) {
        setData(res);
        const place = res.place;
        if(place){
          GetPlaceAPI(place).then((res_place) => setPlace(res_place));
        }
      }
    });
    ProductsAPI().then(res => {setProducts(res.results)})
  }, [id]);


  useEffect(() => {
    if (data?.place) {
      GetPlaceAPI(data.place).then((res) => {
        setPlace(res);
      });
    }
  }, [data]);



  
  

  function RateStars(props) {
    const number = props.number;
    const stars = [];
    let i;
    for (i = 1; i <= number; i++) {
      stars.push(
        <StartIcon key={"star" + i }filled={true} />
      );
    }
    for (let j = 5; j >= i; j--) {
      stars.push(
        <StartIcon key={"star"+ j}filled={false} />
      );
    }
    return <div style={{ display: "flex", flexDirection: "row" }}>{stars}</div>;
  }

  function separarElementos(textoHTML) {
    // Eliminar todas las etiquetas HTML y obtener solo el contenido de texto dentro de ellas
    const contenidoLimpio = textoHTML?.replace(/<[^>]*>/g, '');
    //console.log("contenido Limpio", contenidoLimpio);
  
    

    // Dividir el contenido limpio en elementos basados en saltos de línea, puntos, comas u otros caracteres
    const elementos = contenidoLimpio.split(/[\n]+|[.,;]/).join(" ");
    //console.log("elementos", elementos);
    const textoConSaltosDeLinea = elementos?.replace(/\r?\n|\r/g, '<br>');
    return textoConSaltosDeLinea;
}


  
  const HtmlToReactParser = require("html-to-react").Parser;
  const elementosSeparados = data   && data.description ? separarElementos(data.description) : "";
  let htmlInput = data?.description?.replace(/\r?\n|\r/g, '<br>');
  let htmlToReactParser = new HtmlToReactParser();
  let reactElementMini = htmlToReactParser.parse(elementosSeparados);
  let reactElementFull = htmlToReactParser.parse(htmlInput);
  //console.log("description",data?.description)
  //console.log("full",reactElementFull)
  //console.log("mini",reactElementMini)


      function isOverflowActive(event) {
        return event?.offsetHeight < event?.scrollHeight
    }



    useEffect(() => {
      if (isOverflowActive(textRef.current)) {
          setOverflowActive(true);
          console.log("hay overflow")
          return;
      }
      else{
        console.log("nohayoverflow")
        setOverflowActive(false);
      }
  
    }, [reactElementMini]);




    const handleCarouselLoaded = () => {
      console.log("loaded")
    };
  
  

  if (isMobile) {
    return (
      <div className="FullPostLayoutMobile">
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "100vw" }} />
        <div className="FullPostBoxMobile" style={{ width: box_size }} >
          <div className="FullPostHeader">
            <div className="FullPostHeaderLeft">
              <button className="FullPostHeaderButton" onClick={() => handleGoBack()}>
                <BackIcon />
              </button>
              <ShareModal specificLink={"@"+data?.user?.username+"/post/"+data?.id} id={data?.id} resource="posts" contents={data?.contents} shareBody={"Mira este post de @"+ data?.user?.username +" en Cranberry Chic" }/>
            </div>
            <div className="FullPostHeaderRowButton">
              <FollowButton user_id={data?.user?.id} />
              {data?.user?.id  === me?.id &&
              <div className="SmallButtonGroup">
                <button style={{padding:"6px"}} onClick={() =>navigate(`/@${data?.user?.username}/post/${data?.id}/edit/`)}>
                <EditIcon  width="20px" height="20px"/>
                </button>
                <TrashModal post_id ={data?.id} me={me?.username} />
              </div>
              }
              <WishlistModal id={id} img={data?.contents[0]} />
            </div>
          </div>
          <div className="FullPostContentCarousel" style={{
            minWidth: image_size + "px",
            minHeight: data?.contents[0].content_ratio ==="1:1"  ? image_size + "px" : Math.round((image_size / 3) * 4) + "px",
          }}
          >
          {
            data && data.contents.length > 0 &&
          
            <CarouselContent username={data?.user?.username} id={id} data={data?.contents} size={image_size} mini={false} ratioSquare={data?.contents[0].content_ratio==="1:1" }  onLoad={handleCarouselLoaded}/>
          
          }
          </div>
          <div className="FullPostContentHeader">
            <div className="FullPostContentCreator">
              <button className="FullPostContentCreatorName" onClick={() => navigate("/@"+data?.user?.username)}>
                {data?.user?.full_name}
              </button>
              <div className="FullPostContentCreatorBagde"> </div>
            </div>
            <div className="FullPostContentHeaderSub">
              {place ? (
                <button
                  className="PlaceButton"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => openInNewTab(place?.url)}
                >
                  <PinIcon />
                  {( place.city?  place.city.split(",")[0]  : "" )+ (place.country?   ", " + place.country : "") }
                </button>
              ) : (
                <button
                  className="PlaceButton"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data?.user?.country}
                  
                </button>
              )}
            </div>
          </div>
          <div className="TextContent">
            {place ? (
              <div className="PlaceContent">
                <div className="PlaceContentHeader">
                  <button
                    className="PlaceButtonTitle"
                    onClick={() => openInNewTab(place?.url)}
                  >
                    {place.name}
                  
                  </button>
                  <RateStars  number={data?.grade} />
                </div>
                <div className="PlaceContentSub">
                  {place.streetAddress}
                </div>
                <div className="Divider" />
            
              </div>
            ) : (
              <div />
            )}
           
            <div>
              {
                data?.description && data?.description!=="null"&&
                <div className="FullPostDescription">
                   { showMore ?
                   <div > 
                    {reactElementFull}<br/>
                    <button className="LinkButtonGrey" onClick={() => setShowMore(false)}>  ver menos </button>
                    </div>
                      :
                      <>
                      <div className="miniText" ref={textRef} >
                        {reactElementMini}
                      </div>
                         {overflowActive ?  <button className="LinkButtonGrey" onClick={() => setShowMore(true)}> ver más  </button>   : "" }   
                        
                      </>
                    }
                  
                </div>

              }
             
            </div>
            {data?.description && data?.description!=="null" &&<div className="Divider" style={{margin:"16px"}}/>}

          </div>
              { data && data.albums.some(albumId => [1, 3, 4].includes(albumId))  ? 
                 <RecomendationMenu  title="Recomendados Cranberry" recommendations={data?.recommendations} products={[]} box_size={box_size}  postID={data?.id}/> 
                :
                <></>
              }
              {/* Aqui agregar despues TRAVEL RECOMMENDATION ETC */}
          
        <div className="Spacer" style={{ height:"150px" , width:"10px"}} />     
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default FullPostScreen;




