import { useNavigate } from "react-router-dom";
// import ffmpeg from 'ffmpeg.js/ffmpeg-mp4.js'; // Ajusta el camino según sea necesario


export function goBack(navigation) {
    sessionStorage.setItem('wentBack', 'true');
    navigation(-1); // Retroceder en el historial del navegador
  }



//   const loadFFmpeg = async () => {
//     await ffmpeg.load();
//     console.log('FFmpeg cargado correctamente');
//   };

//   // Llama a la función para cargar FFmpeg
//   loadFFmpeg();

//   const compressVideo = async (inputFile) => {
//     // Obtener el ArrayBuffer del archivo de video original
//     const arrayBuffer = await inputFile.arrayBuffer();

//     // Inicializar FFmpeg
//     const { createFFmpeg, fetchFile } = ffmpeg;
//     const ff = createFFmpeg({ log: true });
//     await ff.load();

//     // Montar el archivo de entrada
//     ff.FS('writeFile', 'input.mp4', new Uint8Array(arrayBuffer));

//     // Ejecutar el comando de compresión
//     await ff.run('-i', 'input.mp4', '-vf', 'scale=-2:480', 'output.mp4');

//     // Leer el archivo de salida comprimido
//     const data = ff.FS('readFile', 'output.mp4');

//     // Crear un objeto Blob del archivo comprimido
//     const compressedVideo = new Blob([data.buffer], { type: 'video/mp4' });

//     return compressedVideo;
// };
