import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isWishlistAPI, addWishlistAPI, deleteWishlistAPI } from "../../API";
import BookmarkIcon from "../../assets/SVG/BookmarkIcon";
import ModalLogin from "./ModalLogin";

const WishlistModal = React.memo((props) => {
    const id = props.id;
    const content_file = props.img?.content_file;
    const [showModal, setShowModal] = useState(false);
    const [isWish, setIsWish] = useState(null);
    const navigate = useNavigate();
    const [me, setMe] = useState();
    const [showLogin, setShowLogin] = useState(false);
    const [loadedWish, setLoadedWish] = useState(false);

    useEffect(() => {
        if (!me) {
            const me_ = JSON.parse(localStorage.getItem("me"));
            setMe(me_);
        }
    }, []);

    useEffect(() => {
        if (!loadedWish) {
            isWishlistAPI(id).then((res) => {
                if (res) {
                    setIsWish(res.status);
                }
            });
            setLoadedWish(true);
        }
    }, [me, id]);

    function handlePressWish() {
        if (isWish === null) {
            setShowLogin(true);
        } else if (!isWish) {
            addWishlistAPI(id).then((res) => {
                setShowModal(true);
                setIsWish(res);
                setTimeout(() => setShowModal(false), 7000);
            });
        } else if (isWish) {
            deleteWishlistAPI(id).then((res) => {
                setShowModal(true);
                setIsWish(res);
                setTimeout(() => setShowModal(false), 7000);
            });
        }
    }

    return (
        <>
            <button className="FullPostHeaderButton" onClick={handlePressWish}>
                {isWish ? (
                    <BookmarkIcon height="20px" width="20px" color={"#B33553"} fill={"#EBC7D0"} />
                ) : (
                    <BookmarkIcon height="20px" width="20px" />
                )}
            </button>
            {showModal && (
                <div className="BottomModal BottomModalOpen" onClick={() => setShowModal(false)}>
                    <div className="BottomModalContent BottomModalContentOpen">
                        <div className="BottomModalRectangle" />
                        <div className="BotomModalRow">
                            <div className="BottomModalWishlist">
                                <div
                                    className="PostMiniPreview"
                                    style={{
                                        backgroundImage: "url(" + content_file + ")",
                                    }}
                                />
                                {isWish ? "Guardado en tu Wishlist" : "Eliminado de tu Wishlist"}
                            </div>
                            <div className="BottomModalIcon">
                                {isWish ? (
                                    <BookmarkIcon height="32px" width="32px" color={"#B33553"} fill={"#EBC7D0"} />
                                ) : (
                                    <BookmarkIcon height="32px" width="32px" />
                                )}
                            </div>
                        </div>

                        <div className="Divider" />
                        <button className="BottomModalButton" onClick={() => navigate("../mywishlist")}>
                            Ver mi Wishlist
                        </button>
                    </div>
                </div>
            )}

            {showLogin && <ModalLogin setShow={setShowLogin} />}
        </>
    );
});

export default WishlistModal;
