import { useState, useEffect } from "react";
import React from "react";
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from "react-google-autocomplete";
import Form from "react-bootstrap/Form";
import {CategoriesAPI ,GarmentTypeAPI,GarmentBrandAPI,EditGarmentAPI,DeleteGarmentAPI,CreateGarmentAPI, GarmentColorAPI, EditPostAPI,GarmentByPostAPI,BrandAPI,DetailsAPI} from "../../API";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Spinner from "react-bootstrap/Spinner";
import PinIcon from "../../assets/SVG/PinIcon";
import TrashIcon from "../../assets/SVG/TrashIcon";

export default function PostFormEdit(props) {
  var options = [
    //{ label: "Todo", value: "0" },
    { label: <><span>Looks</span> <span className='HelpText'>: Comparte tus mejores outfits. </span></>, value: "1" },
    { label: <><span>Travel</span> <span className='HelpText'>: Tus recomendaciones de viajes, destinos, hoteles, restaurantes, experiencias y más. </span></> , value: "2" },
    { label: <><span>Beauty</span> <span className='HelpText'>: Rutinas de belleza, tus favoritos en perfumería, tutoriales y más. </span></>, value: 5 },
    { label:  <><span>Lifestyle</span> <span className='HelpText'>: Tus elegidos en  deporte, wellness, deco, cocina y más. </span></>, value: "4" },
    { label: <><span>Oz</span> <span className='HelpText'>: Comparte el contenido que creaste en nuestro maravilloso mundo de Oz Shop.  </span></>, value: 7 }
  ];



  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => label.props.children[0].props.children).join()
      : "Seleccione por lo menos un álbum";
  };

  var optionExperienceType = [
    { label: "Hoteles", value: "Hoteles" },
    { label: "Restaurantes", value: "Restaurantes" },
    { label: "Bares y Cafeterías", value: "Bares y Cafeterías" },
    { label: "Panoramas Turísticos", value: "Panoramas Turísticos" },
    { label: "Museos y Galerías", value: "Museos y Galerías" },
    { label: "Playas y Costas", value: "Playas y Costas" },
    { label: "Actividades al Aire Libre", value: "Actividades al Aire Libre" },
  ];

  const optionsGrade = [
    { label: "1 estrella", value: 1 },
    { label: "2 estrellas", value: 2 },
    { label: "3 estrellas", value: 3 },
    { label: "4 estrellas", value: 4 },
    { label: "5 estrellas", value: 5 },
  ];


  const data = props.data;
  const place = props.place;
  const [categories, setCategories] = useState([]);
  const [optionsCategories,setOptionsCategories] = useState([]);
  const [albums, setAlbums] = useState([]);
  const files = props.files;
  const ratioSquare = props.ratioSquare;
  const [location, setLocation] = useState(null);
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState([]);
  const [response, setResponse] = useState(null);
  const [isGlobal, setIsGlobal] = useState(true);
  const [isTravel, setIsTravel] = useState(false);
  const [isLifestyle, setIsLifestyle] = useState(false);
  const [isLook, setIsLook] = useState(false);
  const [openModal , setOpenModal] = useState(false);
  const [garmentTypeOptions,setGarmentTypeOptions] = useState([]);
  const [garmentColorOptions,setGarmentColorOptions] = useState([]);
  const [garmentBrandOptions,setGarmentBrandOptions] = useState([]);
  const [postFormCount, setPostFormCount] = useState(0);
  const [garments,setGarments] = useState([]);
  const [garmentsNext,setGarmentsNext] = useState(null);
  const [garmentBrands,setGarmentBrands] = useState([]);
  const [garmentTypes,setGarmentTypes] = useState([]);
  const [deletedGarments,setDeletedGarments] = useState([]);
  const [garmentIDs,setGarmentIDS] = useState([]);
  const [garmentColor,setGarmentColor] = useState([]);
  const [experienceType,setExperienceType] =  useState([]);

  const [clickedPlace, setClickedPlace] = useState(false);


  //Errores 
  const [files_error,setFile_error]= useState(null);
  const [categories_error,setCategories_error]= useState(null);
  const [albums_error,setAlbum_errors]= useState(null);
  const [place_error,setPlace_error]= useState(null);
  const [rate_error,setRate_error] = useState(null);
  const [garment_error,setGarment_error] = useState(null);
  const [uploadErrors, setUploadErrors] = useState(null);
  const [experienceType_error,setExperienceType_error] =  useState(null);


  let navigate = useNavigate();

  useEffect(() => {
    CategoriesAPI().then((res) => {
      const categories = res?.results?.map(item => ({
        value: item,
        label: item
      }));
      const filtered_categories =  categories.filter(objeto => {
          return !optionExperienceType.some(option => 
           option.value === objeto.value
          );
        })
      setOptionsCategories(filtered_categories ? filtered_categories : []);
    })
    GarmentTypeAPI().then((res) => {
      const garment_type = res?.results?.map(item => ({
        value: item.id,
        label: item.value,
      }));
      setGarmentTypeOptions(garment_type ? garment_type : []);
    })
    GarmentColorAPI().then((res) => {
      const garment_color = res?.results?.map(item => ({
        value: item.id,
        label: item.value,
      }));
      setGarmentColorOptions(garment_color ? garment_color : []);
    })
    GarmentBrandAPI().then((res) => {
      const garment_brand = res?.results?.map(item => ({
        value: item.id,
        label: item.name,
      }));
      setGarmentBrandOptions(garment_brand ? garment_brand : []);
    })
  }, []);


  const filterOptions = async (options, filter) => {
    if (!filter) {
      return options;
    }
    try {
      const res = await CategoriesAPI(filter); // Realizar la llamada a la API
      const filteredCategories = res?.results?.map(item => ({
        value: item,
        label: item
      }));

      const secondFillteredCategories =  filteredCategories.filter(objeto => {
        return !optionExperienceType.some(option => 
         option.value === objeto.value
        );
      })
      return secondFillteredCategories;
    } catch (error) {
      console.error('Error al filtrar las opciones:', error);
      return options; // Devolver las opciones originales en caso de error
    }
  };



  const filterGarmentTypes = async (options, filter) => {
    if (!filter) {
      return options;
    }
    try {
      const res = await GarmentTypeAPI(filter); // Realizar la llamada a la API
      const filteredCategories = res?.results?.map(item => ({
        value: item.id,
        label: item.value,
      }));
      return filteredCategories;
    } catch (error) {
      console.error('Error al filtrar las opciones:', error);
      return options; // Devolver las opciones originales en caso de error
    }
  };

  const filterGarmentColor = async (options, filter) => {
    if (!filter) {
      return options;
    }
    try {
      const res = await GarmentColorAPI(filter); // Realizar la llamada a la API
      const filteredCategories = res?.results?.map(item => ({
        value: item.id,
        label: item.value,
      }));
      return filteredCategories;
    } catch (error) {
      console.error('Error al filtrar las opciones:', error);
      return options; // Devolver las opciones originales en caso de error
    }
  };


  const filterGarmentBrands = async (options, filter) => {
    if (!filter) {
      return options;
    }
    try {
      const res = await GarmentBrandAPI(filter); // Realizar la llamada a la API
      const filteredCategories = res?.results?.map(item => ({
        value: item.id,
        label: item.name,
      }));
      return filteredCategories;
    } catch (error) {
      console.error('Error al filtrar las opciones:', error);
      return options; // Devolver las opciones originales en caso de error
    }
  };
  
  const handleSetGarment = (type, index, value) => {
    if (type === "garment_type") {
      const lastValue = value.length> 0 ? [value[value.length - 1]] : [];
      setGarmentTypes((prevTypes) => {
        const updatedTypes = [...prevTypes];
        if (!updatedTypes[index]) {
          updatedTypes[index] = "" // o cualquier valor por defecto que desees
        }
        updatedTypes[index] = lastValue;
        return updatedTypes;
      });
    } else if (type ==="color"){
      setGarmentColor((prevTypes) => {
        const updatedColor = [...prevTypes];
        if (!updatedColor[index]) {
          updatedColor[index] = "" // o cualquier valor por defecto que desees
        }
        updatedColor[index] = value;
        return updatedColor;
      });
    }
    else if (type ==="id"){
      setGarmentIDS((prevTypes) => {
        const updatedIDS = [...prevTypes];
        if (!updatedIDS[index]) {
          updatedIDS[index] = "" // o cualquier valor por defecto que desees
        }
        updatedIDS[index] = value;
        return updatedIDS;
      });
    }
    else{
      const lastValue = value.length> 0 ? [value[value.length - 1]] : [];
      setGarmentBrands((prevBrands) => {
        const updatedBrands = [...prevBrands];
        if (!updatedBrands[index]) {
          updatedBrands[index] = "" // o cualquier valor por defecto que desees
        }
        updatedBrands[index] =lastValue;
        return updatedBrands;
      });
    }
  };




  const handleOneMore = (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del botón
    setPostFormCount(postFormCount + 1); // Incrementar el contador de PostForm
  };
  const handleDeleteGarment = (e,index) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del botón
    setPostFormCount(postFormCount >  1 ? postFormCount - 1 : 1 ); // Incrementar el contador de PostForm
    // Update garmentBrands removing the item at the specified index
    setGarmentBrands(prev => {
      const updatedBrands = [...prev]; // Create a copy of the previous state
      updatedBrands.splice(index, 1); // Remove the item at the specified index
      return updatedBrands; // Return the updated state
  });

  // Update garmentTypes removing the item at the specified index
  setGarmentTypes(prev => {
      const updatedTypes = [...prev]; // Create a copy of the previous state
      updatedTypes.splice(index, 1); // Remove the item at the specified index
      return updatedTypes; // Return the updated state
  });

  // Update garmentColor removing the item at the specified index
  setGarmentColor(prev => {
      const updatedColors = [...prev]; // Create a copy of the previous state
      updatedColors.splice(index, 1); // Remove the item at the specified index
      return updatedColors; // Return the updated state
  });
  if(index <= garmentIDs.length){
    setGarmentIDS(prev => {
      const updatedIDS = [...prev]; // Crear una copia del estado anterior
      const deletedValue = updatedIDS.splice(index, 1)[0]; // Eliminar el elemento en el índice especificado y obtenerlo
      setDeletedGarments(prev => [...prev  , ...[deletedValue]])
      return updatedIDS // Devolver el estado actualizado y el valor eliminado
    });
  }
  };



  useEffect(() => {
    console.log("status",response)
    if (response?.status==true && typeof response?.data.id === "number") {
      setOpenModal(false);
      const me = JSON.parse(localStorage.getItem("me"));
      const combinedGarments = garmentTypes.map((type, index) => ({
        details: type,
        brand: garmentBrands[index] || "",
        color : garmentColor[index] || [], // Si no hay marca para este tipo, establece una cadena vacía
      }));
      //Eliminamos los garments primero 
      console.log("deletedGarments",deletedGarments)
      const promises_deleted = deletedGarments.map( id => DeleteGarmentAPI(id))
      console.log("edited",combinedGarments.slice(0, garmentIDs.length))
      console.log("created", combinedGarments.slice(garmentIDs.length))
      const edited_garments = combinedGarments.slice(0, garmentIDs.length); // Extrae los elementos desde el índice 0 hasta index - 1
      const created_garments = combinedGarments.slice(garmentIDs.length); 
      //Actualizamos los primeros
      const promises_edit = edited_garments.map((garment,index) => EditGarmentAPI(garment,garmentIDs[index],response?.data.id));
      // Crear una promesa para cada llamada a CreateGarmentAPI y almacenarlas en un arreglo
      const promises_create = created_garments.map((garment) => CreateGarmentAPI(garment,response?.data.id));
  
      // Esperar a que todas las promesas se resuelvan
      Promise.all([promises_deleted,promises_edit,promises_create])
        .then(() => {
          // Navegar a la nueva ruta después de que todas las prendas se hayan creado
          navigate("../../@" + me.username + "/post/" + response.data.id);
        })
        .catch((error) => {
          console.error("Error creating garments:", error);
          alert("Oops! Hubo un problema al crear las prendas");
          navigate("../../@" + me.username + "/post/" + response.data.id);
        });
    } else if (response?.status==false) {
      setUploadErrors("Oops! Hubo un problema con el servidor, inténtalo nuevamente");
    }
  }, [response]);



  
  const fetchGarments = async (id) => {
    const fetchGarmentsAPI = async (id) => {
      const res = await GarmentByPostAPI(id);
      const post_garments = res.results;
      setGarments(post_garments);
    };
    await fetchGarmentsAPI(id);
  };

  useEffect(() => {
    if(data){
      setAlbums(options.filter(option => data.albums.includes(parseInt(option.value))))
      setDescription(data.description)
      setRate(optionsGrade.filter(option => data.grade == parseInt(option.value)))
      setExperienceType(optionExperienceType.filter(option => data.categories.includes(option.value)))
      setCategories(data.categories.map(elemento => ({
        label: elemento,
        value: elemento
      })).filter(objeto => {
        return !optionExperienceType.some(option => 
         option.value === objeto.value
        );
      }))
    fetchGarments(data.id);
    }
    
  }, [data]);


  useEffect(() => {
    if (garments.length > 0) {
      // Crear un arreglo de promesas para cada prenda
      const promises = garments.map(async (garment) => {
        const brandPromise = BrandAPI(garment.brand); // Promesa de la marca para esta prenda
        const detailPromises = garment.details.map(detailId => DetailsAPI(detailId)); // Arreglo de promesas de detalles para esta prenda
        const detailResults = await Promise.all(detailPromises); // Esperar a que todas las promesas de detalles se completen
        return Promise.all([garment.id ,brandPromise, detailResults]); // Retornar una promesa que incluye la promesa de la marca y los resultados de las promesas de detalles
      });
  
      // Ejecutar todas las promesas y esperar a que se completen
      Promise.all(promises)
        .then((results) => {
          setPostFormCount(results.length)
          //console.log(results)
          results.map(([garment_id,brand, details],index) => {
            handleSetGarment("id",index,garment_id);
            const current_brand =[{label : brand.name, value: brand.id}];
            handleSetGarment("brand",index,current_brand)
            const colors = [];
            details.map(detail => {
              const item = { value: detail.id,label: detail.value}
              if(detail.detail_type == "color"){
                colors.push(item)
                handleSetGarment("color",index,colors)
              }
              else{
                handleSetGarment(detail.detail_type,index,[item])
              }
            })
          });
        })
        .catch((error) => {
          // Manejar cualquier error que ocurra durante la ejecución de las promesas
          console.error("Error al ejecutar las promesas:", error);
        });
    }
  }, [garments]);
  
  
  

  useEffect(() => {
    setCategories_error(categories_error && (isLook || isLifestyle) && categories.length > 0? null :  categories_error);
    setAlbum_errors(albums_error && albums.length > 0 ? null : albums_error);
    setRate_error(rate_error && isTravel && rate ? null : rate_error) ;
    setPlace_error(place_error && isTravel && (location || place)? null : place_error);
    setGarment_error(garment_error && garmentBrands && garmentColor && garmentTypes &&  garmentBrands.length > 0  && garmentColor.length > 0  && garmentTypes.length > 0  ? null : garment_error);
    setExperienceType_error(experienceType_error && isTravel && experienceType ? null : experienceType_error)
    
  }, [albums,files,categories,location,rate,garmentBrands,garmentColor,garmentTypes,experienceType]);

  useEffect(() => {
    if (albums.some(album => album.value === "2")) {
      setIsTravel(true);
    }
    else{
      setIsTravel(false);
    }
    if (albums.some(album => album.value === "1")) {
      setIsLook(true);
    }
    else{
      setIsLook(false);
    }
    if (albums.some(album => album.value === "4")) {
      setIsLifestyle(true);
    }
    else{
      setIsLifestyle(false);
    }
  }, [albums]);

  const updatePostSequencially = async () => {
    const is_complete = ((isLook || isLifestyle) ? categories.length > 0 : true) && albums.length > 0  && (isTravel ? (rate  && (location || place ) && experienceType.length > 0 ) : true) &&  (isLook ? garmentBrands.length >0   && garmentColor.length > 0  && garmentTypes.length > 0 && garmentTypes.length==garmentBrands.length && garmentBrands.length ==garmentColor.length &&  garmentTypes.length==garmentColor.length: true);
    //console.log(is_complete) 
    if (!is_complete){
        setAlbum_errors(albums.length === 0 ? "Debes seleccionar por lo menos un álbum" : null);
        setCategories_error((isLook || isLifestyle) && categories.length === 0 ? "Debes seleccionar por lo menos una categoría" : null);
        setRate_error(isTravel && !rate.length > 0 ? "Debes seleccionar una nota para la experiencia" : null);
        setPlace_error(isTravel && !(location || place )   ? "Debes seleccionar un lugar para la experiencia" : null);
        setGarment_error(isLook && (garmentBrands.length < 1 ||
          garmentColor.length < 1 ||
          garmentTypes.length < 1 ||
          garmentTypes.length !== garmentBrands.length ||
          garmentBrands.length !== garmentColor.length ||
          garmentTypes.length !== garmentColor.length) ? "Completa por lo menos una prenda o elimina celdas sin completar" : null);
        setExperienceType_error(isTravel && experienceType< 1? "Seleciona una de las opciones de experiencia":null);
      }
      else{
        setOpenModal(true)
        const plain_categories = categories ?  categories.map(option => option.value) : [];
        const plain_experience = experienceType ?  experienceType.map(option => option.value) : [];
        const rate_single = rate[0];
        const post = await EditPostAPI(
          data.id,
          plain_categories.concat(plain_experience),
          albums,
          location,
          !isTravel,
          description,
          rate_single?.value,
          isGlobal,
        );
        setResponse(post)
      }
  };


  const handleDescription = (text) => {
    // Cortar el texto a 300 caracteres
    const trimmedText = text.substring(0, 300);
    
    // Establecer la descripción cortada como estado
    setDescription(trimmedText);
  
    // Mostrar el texto cortado en la consola
    //console.log(trimmedText);
  }



  return (
    
      <Form className="PostForm"
            id='post-edit-form'
            onSubmit={(e) => {
              e.preventDefault();
              updatePostSequencially();
            }}
        >
        {files_error ? <span className="ErrorText"> {files_error} </span> : ""}
        <div className="ProfileInputBox">
          Selecciona uno o más álbumes  {albums_error ? <span className="ErrorText"> {albums_error} </span> : ""}
          <div className={albums_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
            <MultiSelect
              options={options}
              value={albums}
              onChange={setAlbums}
              labelledBy="Albums"
              closeOnChangedValue={false}
              disableSearch={true}
              hasSelectAll={false}
              valueRenderer={customValueRenderer}
              overrideStrings={{ 
                "noOptions": "Sin resultados",
                "search": "Buscar",
                "selectSomeItems": "Seleccionar álbumes"}}
            />
          </div>
        </div>
       {isTravel &&
        <div className="ProfileInputBox">
          Tipo de experiencia {experienceType_error ? <span className="ErrorText"> {experienceType_error} </span> : ""}
          <div className="HelpText">
          Puedes seleccionar más de uno 
          </div>
          <div className={experienceType_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
            <MultiSelect
              options={optionExperienceType}
              value={experienceType}
              onChange={setExperienceType}
              labelledBy="ExperienceType"
              closeOnChangedValue={true}
              disableSearch={true}
              hasSelectAll={false}
              overrideStrings={{ 
                "noOptions": "Sin resultados",
                "search": "Buscar",
                "selectSomeItems": "Selecione tipo de experiencia"}}
            />
          </div>
        </div>}
        <div className="ProfileInputBox">
          Descripción 
            <div className="ProfileInput">
              <Form.Control 
                  type="text"
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => handleDescription(e.target.value)}
              />
              
            <div>
                <div className="ProfileEditContador">
                  {description ? description.length : 0 } /300
                </div>
            </div>
            </div>
          </div>
          {
            isTravel && 
            <div className="ProfileInputBox">
              Ubicación   {place_error ? <span className="ErrorText"> {place_error} </span> : ""}
              { place && (!clickedPlace ?
               <div className="HelpText">
               Agrega un lugar como hotel, restaurant, playa, entre otros.
               </div> 
               :
                <div className="HelpText" onClick={() => setClickedPlace(true)}>
                  Ubicación actual :{place.name + ( place.city?   ", "+  place.city : "" )+  (place.region? +   ", " + place.region  : "" )+  (place.country?   ", " + place.country : "") }
                </div>)
              }
              
              <div className={place_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
                <PinIcon/>
                { place && !clickedPlace ?
                <div className="HelpText" onClick={() => setClickedPlace(true)}>
                {place.name + ( place.city?   ", "+  place.city : "" )+  (place.region? +   ", " + place.region  : "" )+  (place.country?   ", " + place.country : "") }
                </div>
                :
                <>
                
                <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                options={{ types: ["establishment"], fields: ["ALL"] }}
                onPlaceSelected={(place) => {
                  //console.log(place);
                  setLocation(place);
                }}
                />
                </>         
              }
              
              </div>
            </div>
          }

          {
            isTravel && 
            <div className="ProfileInputBox">
              Nota {rate_error ? <span className="ErrorText"> {rate_error} </span> : ""}
              <div className="HelpText">
              Siendo 1 estrella la peor experiencia y 5 estrellas la  mejor 
              </div>
              <div className={rate_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
              <MultiSelect
              options={optionsGrade}
              value={rate}
              onChange={(item) => setRate(item.length > 0 ? [item[item.length - 1]] : [])}
              labelledBy="Nota"
              closeOnChangedValue={true}
              hasSelectAll={false}
              overrideStrings={{ 
              "noOptions": "Sin resultados",
              "search": "Buscar",
              "selectSomeItems": "Elegir Nota",
              }}
              />
            </div>
             
              
            </div>
          }
          
          
        
        {(isLook || isLifestyle) && 
        <div className="ProfileInputBox">
          Agrega categorías a tu publicación{categories_error ? <span className="ErrorText"> {categories_error} </span> : ""}
          <div className={categories_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"} onScroll={() => console.log("Scrolling")}>
                <MultiSelect
                options={optionsCategories}
                value={categories}
                onChange={setCategories}
                labelledBy="Categories"
                closeOnChangedValue={true}
                hasSelectAll={false}
                filterOptions={filterOptions}
                overrideStrings={{ 
                  "noOptions": "Sin resultados",
                  "search": "Buscar",
                  "selectSomeItems": "Categorías",
                  "create": "Crear" }}
                />
          </div>
        </div>}
        {isLook &&             
        <div className="ProfileInputBox">
          Cuéntanos sobre tu look.{garment_error ? <span className="ErrorText"> {garment_error} </span> : ""}
          { [...Array(postFormCount)].map((_, index) => (
          <div key={index} className={garment_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"} onScroll={() => console.log("Scrolling")}>
            <MultiSelect
            options={garmentBrandOptions}
            value={garmentBrands[index] || []}
            onChange={(value) => handleSetGarment("brands",index,value)}
            labelledBy="Marca"
            closeOnChangedValue={true}
            filterOptions={filterGarmentBrands}
            isCreatable={true}
            hasSelectAll={false}
            overrideStrings={{ 
              "noOptions": "Sin resultados",
              "search": "Buscar",
              "selectSomeItems": "Marca",
              "create": "Crear" }}
          />
          <MultiSelect
            options={garmentTypeOptions}
            value={garmentTypes[index] || []}
            onChange={(value) => handleSetGarment("garment_type",index,value)}
            labelledBy="Tipo de Prenda"
            closeOnChangedValue={true}
            hasSelectAll={false}
            filterOptions={filterGarmentTypes}
            overrideStrings={{ 
            "noOptions": "Sin resultados",
            "search": "Buscar",
            "selectSomeItems": "Tipo de Prenda",
           }}
          />
          <MultiSelect
            options={garmentColorOptions}
            value={garmentColor[index] || []}
            onChange={(value) => handleSetGarment("color",index,value)}
            labelledBy="Color de Prenda"
            closeOnChangedValue={false}
            hasSelectAll={false}
            filterOptions={filterGarmentColor}
            overrideStrings={{ 
            "noOptions": "Sin resultados",
            "search": "Buscar",
            "selectSomeItems": "Color de Prenda",
           }}
          />
          <button className="FullPostHeaderButton" onClick={(e) => handleDeleteGarment(e,index) }>
               <TrashIcon width='20px' height='20px' color='#696969' />
          </button>
          </div>
        ))}
          <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}> <button  className="RecentPostButton" onClick={handleOneMore}> + Agrega otra prenda</button></div>
        </div>
        }

        <div className="Divider" />
        <div className="FormOptions">
        Compartir post en el feed principal de Cranberry  <Switch  onColor={"#C7506C"} onHandleColor={"#EBC7D0"} uncheckedIcon={false} handleDiameter={12} checkedIcon={false} width={40} height={20} checked={isGlobal}  onChange={() => setIsGlobal(!isGlobal)}/>
        </div>
        
        {openModal && 
          <div className="LoadingModal">
              { uploadErrors ?
              (
              <div className="LoadingModalBox">
               <span>{uploadErrors} </span>
                <button className="RecentPostButton" onClick={() => navigate("../../new")}> Volver </button>
              </div>
              ) : (
              <div className="LoadingModalBox">
               <span>Estamos subiendo tu post </span>
               <Spinner />
              </div>
              )}
           
          </div>
        }
      </Form>
  )}  