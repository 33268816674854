import React from "react";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import { useNavigate} from 'react-router-dom';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';



import BackIcon from "../../assets/SVG/BackIcon";


function BenefitsLevel() {
    const navigate = useNavigate();

    return ( 
    <div className='ProfileLayout' >
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className='ProfileBox' >
            <div className='ProfileHeaderBar'>
            <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
                <BackIcon />
            </button>
            <div className="ProfileEditTitle"> Términos y Condiciones de Uso</div>
            <div className='FullPostHeaderButton'>
                <div style={{width: "20px", height:"20px"}} />
            </div>
            </div>
            <div className="ProfileEditBody">
            

<div className="TitleHelp"> 
¡Bienvenido a Cranberry Chic! </div>
<div className="TextHelp"> Por favor, lee atentamente los siguientes términos y condiciones de uso antes de utilizar nuestra plataforma. 
Al acceder o utilizar esta plataforma, estás aceptando cumplir con estos términos y condiciones. Si no estás de acuerdo con alguna parte, por favor abstente de utilizar nuestra plataforma. </div>
<div  className="SubTitleHelp">
            <ol>
                <li><Link to="Q1" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>Uso de la plataforma</Link></li>
                <li><Link to="Q2" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>Propiedad del Contenido</Link></li>
                <li><Link to="Q3" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>Responsabilidad del Usuario</Link></li>
                <li><Link to="Q4" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>Compras en Sitios Externos</Link></li>
                <li><Link to="Q5" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>Modificaciones</Link></li>
                <li><Link to="Q6" activeClass="active" className="Q1" spy={true} smooth={true} duration={500} offset={-60}>Ley Aplicable</Link></li>
            </ol>
</div>



<Element name="Q1" className="element" >
<div className="SubTitleHelp">1. Uso de la plataforma</div>
<div  className="TextHelp">1.1. Cranberry Chic es una plataforma en línea que permite a los usuarios compartir contenido, incluyendo imágenes, texto y otros materiales ("contenido"). Al entrar, estás aceptando que el contenido que publiques o compartas sea propiedad de Cranberry Chic.</div>
<div  className="TextHelp">1.2. Tú eres responsable de todo el contenido que publiques en nuestra plataforma. Asegúrate de tener los derechos necesarios para compartir cualquier contenido que publiques.</div>
</Element>

<Element name="Q2" className="element" >
<div className="SubTitleHelp">2. Propiedad del Contenido</div>
<div  className="TextHelp">
2.1. Todo el contenido publicado en Cranberry Chic, incluyendo pero no limitado a textos, imágenes, videos y enlaces, es propiedad de Cranberry, a menos que se indique lo contrario.</div>
<div  className="TextHelp">
2.2. No está permitido copiar, reproducir o distribuir el contenido de Cranberry Chic sin el consentimiento expreso por escrito de Cranberry Chic.</div>
</Element>

<Element name="Q3" className="element" >
<div className="SubTitleHelp">3. Responsabilidad del Usuario
</div>
<div  className="TextHelp">
3.1. Al utilizar Cranberry Chic, aceptas indemnizar y eximir de responsabilidad a Cranberry Chic y a sus empleados, directores y afiliados de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, que surja de tu uso de la plataforma, tu violación de estos términos y condiciones, o tu violación de cualquier ley o derecho de terceros.
</div>
</Element>


<Element name="Q4" className="element" >
<div className="SubTitleHelp">4. Compras en Sitios Externos</div>
<div  className="TextHelp">4.1. Cranberry Chic puede contener enlaces a sitios web externos que ofrecen productos o servicios. No nos hacemos responsables de ninguna compra que realices en estos sitios externos. Los enlaces a sitios externos se proporcionan únicamente como una conveniencia para los usuarios de Cranberry Chic. </div>
<div  className="TextHelp">4.2. Las imágenes de productos que se muestran en Cranberry Chic son recopiladas de otras páginas web y no son propiedad de Cranberry Chic. No nos hacemos responsables del contenido o la exactitud de las imágenes de productos recopiladas.</div>
</Element>


<Element name="Q5" className="element" >
<div className="SubTitleHelp">5. Modificaciones</div>
<div  className="TextHelp">5.1. Cranberry Chic se reserva el derecho de modificar o actualizar estos términos y condiciones en cualquier momento sin previo aviso. Es responsabilidad del usuario revisar periódicamente estos términos y condiciones para estar al tanto de cualquier cambio.</div>
</Element>


<Element name="Q6" className="element" >
<div className="SubTitleHelp">6. Ley Aplicable</div>
<div  className="TextHelp">6.1. Estos términos y condiciones se rigen por las leyes de Santiago, Chile y cualquier disputa que surja en relación con estos términos y condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de Santiago, Chile.</div>
</Element>

<div className="TextHelp">Al utilizar Cranberry Chic, aceptas estar sujeto a estos términos y condiciones. Si tienes alguna pregunta o inquietud sobre estos términos y condiciones, por favor contáctanos. ¡Gracias por ser parte de Cranberry Chic! </div>
</div>
<div className="RecomendationSpacer" />
</div>
</div>

     );
}

export default BenefitsLevel;