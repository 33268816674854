import React, {useEffect , useState} from "react";
import { myBenefitsAPI, fetchMoreAPI, myRewardsAPI } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import Benefit from "../Components/Benefit";
import NoContentMessage from "../Components/NoContentMessage"
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import ProgressBar  from "../Components/RewardsBar";



//Icons
import BackIcon from "../../assets/SVG/BackIcon";

// WAITLISTED = -1
// SENT = 0
// ACCEPTED = 1
// REJECTED = 2
// IGNORED = 3
// SUCCEEDED = 4
// FAILED = 5






function Benefits(props) {
  const [data, setData] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [next, setNext] = useState(null);
  const [activeTab,setActiveTab] = useState(1)
  const navigate = useNavigate();

  


  useEffect(() => {
    let filter = ""
    myBenefitsAPI(filter).then((res) => {
      setData(res.results);
      setNext(res.next);
    });
    myRewardsAPI().then((res) => setRewards(res));
  }, [activeTab]);



  function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
    }
  
  
  
  const fetchMore = () => {
    fetchMoreAPI(next,true).then((res) => {
      setData((data) => {
        if (res?.results) {
          return [...data, ...res.results];
        } else {
          return data;
        }
      });
      setNext(res.next);
    });
  };

    return (
      <div className="BenefitLayout">
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className="BenefitsBox">
          <div className='ProfileHeaderBar'>
              <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
                  <BackIcon color="#696969" />
              </button>
              <div className="ProfileEditTitle"> Mis Beneficios </div>
              <button className='FullPostHeaderButton'>
                  <div style={{width:"20px", height:"24px"}} />
              </button>
          </div>
          <div className="BenefitsTabs">
          <div className="BenefitsTab">
              <div className={activeTab === 1? "TitleTab TitleTabActive" : "TitleTab"}
              onClick={() => setActiveTab(1)}> 
                DESAFÍOS
              </div>
                {activeTab === 1  && <div className="TabRectangle" /> }
            </div>
            <div className="BenefitsTab">
              <div className={activeTab === 0 ? "TitleTab TitleTabActive" : "TitleTab"}
              onClick={() => setActiveTab(0)} >
                  REWARDS
              </div>
                {activeTab === 0  && <div className="TabRectangle" /> }
            </div>
           
            
          </div>
          <div style={{width:"90%", height:"1px",backgroundColor:"lightgray",marginTop:"-18px"}} />
          { activeTab === 1  &&
             <InfiniteScroll
             dataLength={data ? data.length : 5}
             next={fetchMore}
             hasMore={next ? true : false}
             loader={<h4>Loading...</h4>}
             
           >
 
             <div className="BenefitsFeed ">
             {data && data.length>0
                 ? data.map((benefit, index) => {
                     return (
                       <Benefit key={index} data={benefit} />
                     );
                   })
                 : <NoContentMessage customMessage="Por el momento, no tienes beneficios." /> }
            
             </div>
              
           </InfiniteScroll>
          }
          { activeTab === 0 && 
          <div className="RewardsBox">
           <div className="RewardsSmallTitle">
           <div> Mi Nivel Cranberry </div>
           <div> Haz obtenido  {rewards?.totalPoints ? rewards.totalPoints  : 0 } puntos al día { getCurrentDate("/")} </div>
           </div> 
           <div className="CenteredTitle"> 
            { rewards?.totalPoints > 100 && rewards?.totalPoints < 300 ? "Rising Star" : <span style={{color:"lightgrey"}}> Principiante </span> } 
            { rewards?.totalPoints >= 300 && rewards?.totalPoints < 650 &&  "Trend Setter" } 
            { rewards?.totalPoints >= 650 && rewards?.totalPoints < 1000 &&  "Style Icon" } 
            { rewards?.totalPoints >= 1000 &&  "Super Cranberry" } 
           </div>
           <div className="RewardsProgressBar">
            <ProgressBar  y1={rewards?.totalPoints }/>
           </div>
           <div className="RewardsQuestions">
           </div>
           <div className="FAQRewards">
                <button className="HelpButton" onClick={() => navigate("../../earnpoints")}>
                  ¿Cómo ganar puntos?
                </button>
                <button className="HelpButton" onClick={() => navigate("../../benefitslevel")}>
                  Beneficios por Nivel
                </button>
                <button className="HelpButton"onClick={() => navigate("../../FAQrewards")}>
                  Otras preguntas frecuentes
                </button>
            </div>
           
           <div className="RecentActivityBox">
            <div> Actividad Reciente </div>
            <div style={{width:"100%", height:"1px",backgroundColor:"lightgray"}} />
            { rewards?.results.length > 0  ? 
                rewards?.results.map((reward, index) => {
                     return (
                      <>
                      <div className="ActividadRecienteBox">
                      <div className="ActividadRecienteText">
                        <span>
                          {reward.name}
                        </span>
                        <span>
                         {reward.description}
                        </span>
                      </div>
                      <div className="ActividadRecientePoints">
                        <span className="PointsNumber">
                          +{reward.points}
                        </span>
                        <span>
                          ptos
                        </span>
                      </div>
                    </div>
                  <div style={{width:"100%", height:"1px",backgroundColor:"lightgray"}} />
                  </>
                     );
                   })
               : 
              <div> No tienes puntos aun </div>

            }
           
             
            
           </div>

          </div>

          }
         
          <div style={{height:"100px",width:"10px"}} />
        </div>
      </div>
  )}



export default Benefits;