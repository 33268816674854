import React from "react";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import { useNavigate} from 'react-router-dom';
 
import BackIcon from "../../assets/SVG/BackIcon";

function Help() {

    const navigate = useNavigate();

    return ( 
    <div className='ProfileLayout' >
        <ScrollToTopOnMount />
        {/* {message  && 
                <button className={`SnackBar ${message ? 'show' : ''}`} style={{ top: "70px" }} onClick={() => setMessage(null)}>
                Tu perfil esta actualizado !
                <CloseIcon color='#fafafa' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setMessage(null) }} />
                </button>
        }
        {error  && 
                <button className={`SnackBar ${error ? 'show' : ''}`} style={{ top: "70px" }} onClick={() => setError(null)}>
                Error al actualizar el perfil : {error}
                <CloseIcon color='#fafafa' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setError(null) }} />
                </button>
        } */}
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className='ProfileBox' >
            <div className='ProfileHeaderBar'>
            <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
                <BackIcon />
            </button>
            <div className="ProfileEditTitle"> Ayuda </div>
            <div className='FullPostHeaderButton'>
                <div style={{width: "20px", height:"20px"}} />
            </div>
            </div>
            <div className="ProfileEditBody">
                <button className="HelpButton" onClick={() => navigate("../../FAQ")}>
                    Preguntas Frecuentes
                </button>
                <button className="HelpButton" onClick={() => navigate("../../termsandconditions")}>
                    Términos y condiciones de uso
                </button>
                {/* <button className="HelpButton"onClick={() =>    }>
                    ¿Quiénes somos? 
                </button> */}
            </div>
            </div>
        </div>
    
     );
}

export default Help;