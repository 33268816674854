import React, { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';

function ScrollTracker(props) {
  const { data, next,loaded,wentBack } = props;

  useEffect(()=> {
    if(data){
      sessionStorage.setItem('presavedData', JSON.stringify(data));
      sessionStorage.setItem('presavedLenght', data?.length);
    }
    if(next){
      sessionStorage.setItem('next',next)
    }
  }, [data,next])

  useEffect(()=>{
    if(wentBack){
      sessionStorage.setItem('wentBack', 'false');
    }
  },[wentBack])

  useEffect(()=>{
    if(loaded && wentBack){
      const clicked_post = sessionStorage.getItem('clicked_post')
      scroller.scrollTo(clicked_post, {
        duration: 100,
        delay: 100,
      });
    }
  },[loaded])

  return (
    <div/>
  );
}

export default ScrollTracker;

