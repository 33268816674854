//LIBRARY IMPORTS
import React, { useEffect, useState } from "react";


// MY CODE IMPORTS
import CarouselContent from "./CarouselContent";
import ShareModal from './ShareModal';
import WishlistModal from './WishlistModal';
import PinIcon from "../../assets/SVG/PinIcon";
import { PostAPI,GetPlaceAPI } from "../../API";


const InfoBar = React.memo(({ type, barSize, data, place }) => {
  return (
    <div className="CardInfoActionsMedium" style={{ width: barSize }}>
      <div className="CardInfoUserName">
        {window.innerWidth < 768 && window.innerWidth >= 480 && type !== "l" && data.user?.username?.toLowerCase()}
        {(window.innerWidth >= 768 || type === "l") && data.user?.full_name + " "}
        {place && (
          <div className="CardInfoPlace">
            <PinIcon />
            {" " + (place.city ? place.city.split(",")[0] : "") + (place.country ? ", " + place?.country : "")}
          </div>
        )}
      </div>

      <div className="CardInfoButtonsMedium">
        <div>
          <ShareModal
            specificLink={"@" + data?.user?.username + "/post/" + data?.id}
            id={data?.id}
            resource="posts"
            contents={data?.contents}
            shareBody={"Mira este post de @" + data?.user?.username + " en Cranberry Chic"}
          />
        </div>
        <WishlistModal id={data?.id} img={data?.contents[0]} />
      </div>
    </div>
  );
});


function PostScreen(props) {
  const size = props.size;
  const data = props.data;
  const onLoad = props.onLoad;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [boxSize, setBoxSize] = useState(0);
  const [barSize, setBarSize] = useState(0);
  const current_href = window.location.href.split('/')
  const URL = current_href[0] + "//" + current_href[2] + "/@";
  const [place, setPlace] = useState();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    console.log(data)
    if(data.place){
          GetPlaceAPI(data.place).then((res_place) => {setPlace(res_place); console.log(res_place)});
    }
    console.log("post data",data)
  }, [data.place]);


  
  const [opacityLoader,setOpacityLoader] = useState(0);
  const handleCarouselLoaded = () => {
    setOpacityLoader(1);
  };


  useEffect(() => {
    let newBoxSize;
    let newBarSize;

    if (size === 's') {
      newBoxSize = Math.round((windowWidth - 8 * 5) / 4);
      if (newBoxSize > 250 ){
        newBoxSize = 250
      }
      newBarSize = newBoxSize - 16;
    } else if (size === 'm') {
      newBoxSize = Math.round((windowWidth - 8 * 3) / 2);
      if (newBoxSize > 300 ){
        newBoxSize = 300
      }
      newBarSize = newBoxSize - 16;
      
    } else {
      newBoxSize = windowWidth - 8 * 2;
      if (newBoxSize > 500 ){
        newBoxSize = 500
      }
      newBarSize = newBoxSize - 24;
    }

    setBoxSize(newBoxSize);
    setBarSize(newBarSize);
  }, [size, windowWidth]);
 
  return (
      <div className={size==="l"  ? "PostBox PostBoxLarge" : "PostBox PostBoxMedium"} 
      style={{width : boxSize ,
          minWidth: boxSize + "px",
          minHeight: data?.contents[0].content_ratio ==="1:1"  ? 
          boxSize + "px" : Math.round((boxSize / 3) * 4) + "px",
          opacity : opacityLoader,
         transition:" opacity 0.5s ease"
          }} >
            <CarouselContent username={data?.user?.username} id={data.id} data={data.contents}  size={barSize} mini={true} ratioSquare={data?.contents[0].content_ratio==="1:1" } onLoad={handleCarouselLoaded}/>
            <InfoBar type={size} barSize={barSize} data={data} place={place} /> 
      </div>
  );
}

export default PostScreen;
