import React, { useEffect, useState } from "react";
import { SearchAllAPI,PostAPI } from "../../API";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FollowButton from "../Components/FollowButton";

import ProductBox from "../Components/ProductBox";
import PostScreen from "../Components/Post";
import Masonry from "react-masonry-css";
import SearchIcon from "../../assets/SVG/SearchIcon";
import CloseIcon from "../../assets/SVG/CloseIcon";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import NoContentMessage from "../Components/NoContentMessage";
import SearchAutocomplete from "../Components/SearchAutoComplete";



export default function Search() {
  let navigate = useNavigate();
  const search = useParams()?.name;
  const size  = window.innerWidth -48;
  const [newSearch, setNewSearch] = useState(search)
  const [activeTab,setActiveTab] = useState(1);
  const [posts,setPosts] = useState([]) ;
  const [users,setUsers] = useState([]) ;
  const [products,setProducts]  = useState([]) ;
  let productBox_size = Math.round(window.innerWidth/2) - 18 -12 - 16  
  if (productBox_size > 150){
    productBox_size = 150 ;
  } 
  const [columns, setColumns] = React.useState("m");
  const [breakpointCols, setBreakpointCols] = React.useState({
    default: 2,
    1300: 2,
    900: 2,
  });

  useEffect(() => {
    SearchAllAPI(search.toLowerCase()).then((res) => {
      setProducts(res.products);
      setPosts(res.posts)
      setUsers(res.users)
    });
  }, [search]);


  useEffect(() => {
    const postsLength = posts? posts.length : 0;
    const productsLength = products? products.length : 0 ;
    const usersLength = users? users.length : 0 ;
    
    const mayor = Math.max(postsLength, productsLength, usersLength)
    if (postsLength === mayor ) {
      setActiveTab(0);
    } else if ( usersLength === mayor) {
      setActiveTab(1);
    } else if (productsLength === mayor ) {
      setActiveTab(2);
    }
  }, [posts, products, users]);


  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const uniquePostIds = new Set(products.flatMap(product => product.posts)); // Crea un conjunto de postIds únicos
        const postsPromises = Array.from(uniquePostIds).map(PostAPI); // Mapea los postIds únicos y realiza la búsqueda de las publicaciones
        const fetchedPosts = await Promise.all(postsPromises);
        
        // Agregar nuevos posts sin repetir ids
        setPosts(prevPosts => {
          const newPosts = [...prevPosts];
          fetchedPosts.forEach(post => {
            if (!newPosts.some(existingPost => existingPost.id === post.id)) {
              newPosts.push(post);
            }
          });
          return newPosts;
        });
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
  
    fetchPosts();
  }, [products]);
  
  



  const handleEnter = (event) => {
    if (event.key === "Enter") {
      navigate('/search/'+ newSearch)
    }

  };





  return (
    <div className="SearchLayout">
      <ScrollToTopOnMount />
      <div className="HeaderSpacer" style={{height:"130px", width:"30px"}} />
        <div className="SearchBox">
          <div className="ProfileInputBox">
              <SearchAutocomplete size={size} searched={search} />
            </div>
            <div className="SearchTabBox">
            <div className="SearchTabs" style={{ marginTop:"env(safe-area-inset-top)"}}>
              <div className="SearchTab">
                <div className={activeTab === 0 ? "TitleTab TitleTabActive" : "TitleTab"}
                onClick={() => setActiveTab(0)} >
                   <div>Posts </div>
                   <div>{posts? "("  + posts.length + ")" : ""}</div>
                </div>
                  {activeTab === 0  && <div className="TabRectangle" /> }
              </div>
              <div className="SearchTab">
                <div className={activeTab === 1? "TitleTab TitleTabActive" : "TitleTab"}
                onClick={() => setActiveTab(1)}> 
                  Perfiles
                  <span>{users? "("  + users.length + ")" : ""}</span>
                </div>
                  {activeTab === 1  && <div className="TabRectangle" /> }
              </div>
              <div className="SearchTab">
                <div className={activeTab === 2 ? "TitleTab TitleTabActive" : "TitleTab"}
                onClick={() => setActiveTab(2)} > 
                  Productos
                  <span>{products? "("  + products.length + ")" : ""}</span>
                </div>
                    {activeTab === 2  && <div className="TabRectangle" /> }
              </div>
            </div>
            </div>


            {activeTab ===0 && posts && posts?.length > 0  &&
            <div className="PostSearchFeed">
             <Masonry
             
             breakpointCols={breakpointCols}
             className={columns === "l" ? "my-masonry-grid my-masonry-grid-large" : "my-masonry-grid"}
             columnClassName="my-masonry-grid_column" >
             {posts.map((post, index) => 
                     <PostScreen className="PostScreen" key={"post_" +index} data={post} size={columns} index={index} />
              )}
            </Masonry>
            </div>
            }
            {activeTab ===0 && posts && posts?.length === 0  &&
            <NoContentMessage customMessage={"No se encontraron posts  relacionados a la búsqueda :  " + search} />
            }
            {activeTab ===1 && users && users?.length > 0  &&
              <div className="UsersFeed">
              {users.map((usuarios, index) => (
                <div className="UserRow" key={"user_" +index}  >
                  <div className="UserInfo" onClick={() => navigate("../../@"+ usuarios.username)}> 
                    <div className="ProfileMiniImage" style={{backgroundImage: "url(" + usuarios?.profile_picture + ")",}} />
                    <div className="ProfileMiniFullname">
                        {usuarios?.full_name}
                        <div className="ProfileMiniSubtitles"> {usuarios.posts_qty ? usuarios.posts_qty + "posts" : "" }</div>
                    </div>
                  </div>
                  
                  <FollowButton user_is={usuarios.id} />
                </div>
              ))}
              </div>
            }
            {activeTab ===1 && users && users?.length === 0  &&
            <NoContentMessage customMessage={"No se encontraron perfiles de usuario relacionados a la búsqueda:  " + search} />
            }
            {activeTab ===2 && products && products?.length > 0  &&
              <div className="ProductosFeed" >
              <div className="ProductsContainer"> 
              <div className="ProductsBox">
              {products.map((producto,index) => (
              <button onClick={() => navigate("../../product/"+ producto.id)}>
                <ProductBox key={index} product={producto} i={producto.id} size={productBox_size} />
              </button>
              ))}
              </div>
              </div>
              </div> 
            }
            {activeTab ===2 && products && products?.length === 0  &&
            <NoContentMessage customMessage={"No se encontraron productos relacionados a la búsqueda : " + search}    />
            }   
        </div>
    </div>
  );
}
