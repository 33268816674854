import React from 'react';

const OzIcon = ({ width = "36px", height = "18px", color = "#B33553" }) => (
    <svg version="1.1" viewBox="0 0 1973 1220" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <path transform="translate(597,322)" d="m0 0h26l23 2 24 4 26 7 25 9 25 12 16 9 16 11 13 10 15 13 19 19 9 11 10 13 10 15 12 21 9 19 9 24 7 26 4 22 2 17v47l-3 24-5 24-8 26-8 20-8 17-13 22-12 17-9 11-12 14-14 14-11 9-12 10-21 14-21 12-22 10-33 11-22 5-20 3-10 1h-47l-24-3-23-5-24-7-20-8-25-12-21-13-16-12-11-9-12-11-15-15-9-11-13-17-12-19-12-23-7-16-10-30-5-21-4-28-1-14v-27l2-23 5-28 7-25 10-26 9-19 13-22 10-14 11-14 11-12 8-9 8-7 11-10 20-15 21-13 19-10 27-11 27-8 25-5 16-2z"/>
        <path transform="translate(1257,322)" d="m0 0h361l-24 42-13 22-34 58-14 24-51 87-14 24-51 87-14 24-51 87-14 24-51 87-4 5-6 3h-8l-6-3-5-6-1-3z"/>
        <path transform="translate(1204,461)" d="m0 0 9 1 8 7 1 3v425h-333l4-7 13-18 12-17 13-18 14-20 13-18 14-20 13-18 24-34 13-18 24-34 13-18 24-34 13-18 14-20 13-18 24-34 13-18 24-34 11-15 6-4z"/>
        <path transform="translate(899,322)" d="m0 0h323v15l-4 24-6 20-8 18-10 16-8 10-9 10-9 9-17 13-15 9-17 8-19 6-14 3-18 2h-17l-18-2-21-5-16-6-16-8-14-9-10-8-12-11-10-11-11-15-11-19-8-20-5-21-1-18z"/>
        <path transform="translate(1486,743)" d="m0 0h23l20 3 15 4 16 6 19 10 16 12 15 14 9 11 10 14 10 18 7 19 4 18v24l-1 1h-306l1-18 5-25 7-20 10-19 9-12 12-14 11-10 15-11 14-8 15-7 19-6 16-3z"/>
    </svg>
);

export default OzIcon;