import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { updateProfileAPI } from "../../API";
import CloseIcon from "../../assets/SVG/CloseIcon";
import NewAvatar from "../Components/AvatarEditor";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";


//Icons
import BackIcon from "../../assets/SVG/BackIcon";
import { InstagramIcon, TiktokIcon } from "../../assets/SVG/SocialIcons";
import DiamondIcon from "../../assets/SVG/DiamondIcon";
import ConfigIcon from "../Components/ConfigIcon";

export default function EditProfile() {
  const [data, setData] = useState(null);
  const [profileDescription, setProfileDescription] = useState();
  const [igUser, setIgUser] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [tiktokUser, setTiktokUser] = useState();
  const [image, setImage] = useState(null);
  const [show, setShow] = useState();
  const navigate = useNavigate();
  const [newImage,setNewImage]  = React.useState(null);
  const [message,setMessage] = React.useState();
  const [error,setError] = React.useState();
  const [deletedImage, setDeletedImage] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [copied,setCopied] = useState(false);
  const [inviteText,setInviteText] = useState(null);

    const handleShowConfigModal = (e) => {
      e.preventDefault();
      setShowModal(!showModal);
    } 


  useEffect(() => {
    const me = JSON.parse(localStorage.getItem("me"));
      if (me) {
        setFirstname(me.first_name);
        setLastname(me.last_name)
        setData(me);
        setProfileDescription(me.profile_description);
        setIgUser(me.ig_user);
        setTiktokUser(me.tiktok_user);
        setInviteText(`¡Hola! Te invito a unirte a la nueva app de Cranberry Chic, el mejor espacio para descubrir las últimas tendencias en moda, estilo de vida, viajes y mucho más. Regístrate utilizando mi código de referencia ${me?.referral_code} o a través del siguiente enlace: ${process.env.REACT_APP_URL}register/code=${me?.referral_code} ¡No te lo pierdas!`)
      }
  }, []);

  useEffect(() => {
    if (profileDescription?.length > 300) {
      setProfileDescription(profileDescription?.substring(0, 300));
    }
  }, [profileDescription]); // Se ejecutará cada vez que profile_description cambie


  return (
    <div className='ProfileLayout' >
      <ScrollToTopOnMount />
      {message  && 
              <button className={`SnackBar ${message ? 'show' : ''}`} style={{ top: "70px" }} onClick={() => setMessage(null)}>
                Tu perfil esta actualizado !
                <CloseIcon color='#fafafa' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setMessage(null) }} />
              </button>
      }
      {error  && 
              <button className={`SnackBar ${error ? 'show' : ''}`} style={{ top: "70px" }} onClick={() => setError(null)}>
                {error}
                <CloseIcon color='#fafafa' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setError(null) }} />
              </button>
      }
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (newImage) {
                updateProfileAPI(
                  deletedImage,
                  newImage,
                  profileDescription,
                  tiktokUser,
                  igUser,
                  firstname,
                  lastname
                ).then(res => 
                  {if(res.status){
                    setMessage(res.message)
                  }
                  else{
                    setError(res.message)
                  }}
                )
          }
          else {
            updateProfileAPI(
              deletedImage,
              null,
              profileDescription,
              tiktokUser,
              igUser,
              firstname,
              lastname
            ).then(res =>
              {
              console.log(res)
                if(res.status){
                setMessage(res.message)
              }
              else{
                setError(res.message)
              }}
            )
          }
        }}>

        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className='ProfileBox' >
          <div className='ProfileHeaderBar'>
            <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
              <BackIcon />
            </button>
            <div className="ProfileEditTitle"> Editar Perfil </div>
            <div className='FullPostHeaderButton' onClick={(e) => handleShowConfigModal(e)}                >
                  <ConfigIcon />
                  {showModal && 
                      <div className='ConfigModal'>
                        <button className='ConfigOptionsButton' onClick={() => navigate("/@"+data?.username+"/settings")}>
                          Configuración
                        </button>
                      </div>
                  }
            </div>
          </div>
          <div className="ProfileEditBody">
            <div className="ProfileImageChange">
              { deletedImage ? <div className="ProfileMiniImage" /> :
                           <div className="ProfileMiniImage">
                           {data && data.profile_picture && (
                             <img
                               src={newImage ? URL.createObjectURL(newImage) :  `${data.profile_picture}?${new Date().getTime()}`}
                               alt="Perfil"
                               style={{
                                 width: "100%",
                                 height: "100%",
                                 objectFit: "cover",
                                 objectPosition: "center",
                                 borderRadius:"60px"
                               }}
                             />
                           )}
                         </div>
              }
              <div className="ProfileImageFlex">
              <div className="ProfileMiniFullname">
                {data?.full_name}
              </div>
              <button className="ProfileMiniPhoto" onClick={(e) => { e.preventDefault(); setShow(true) }} >
                Cambiar Foto
              </button>
              </div>
            </div>
            
            <button className='ProfileInviteButton' onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigator.clipboard.writeText(inviteText).then(() => {
                  setCopied(true);
                });
              }}>
                {copied ?  "Invitacion copiada" : "Invitar Amigos" }
            </button> 
            
            <div className="ProfileInputBox">
            Nombre
            <div className="ProfileInputLine" style={{width:"100%"}}>
              <Form.Control
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            Apellido 
            <div className="ProfileInputLine" style={{width:"100%"}}>
              <Form.Control
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
              Bio
              <div className="ProfileInput">
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={3}
                  value={profileDescription}
                  onChange={(e) => setProfileDescription(e.target.value)}
                />
                <div>
                  <div className="ProfileEditContador">
                    {profileDescription ? profileDescription.length : 0} /300
                  </div>
                </div>
              </div>
            </div>
            <div className="ProfileEditSocials">
              <span style={{fontWeight:"bold"}}>Tus links en Redes Sociales</span>
              {data?.ig_user && data?.tiktok_user ?
                <></>
                :
                <div className="ProfileEditBannerIncomplete">
                  <div className="ProfileEditBannerIcon">
                    <DiamondIcon color="#C7506C" />
                  </div>

                  ¡Completa tu perfil para convertirte en una nueva creadora de contenido Cranberry!

                </div>

              }
              <div className="ProfileSocialsInput">
                <div className="ProfileSocialsRow">
                  <InstagramIcon width="15px" height="15px" />
                  instagram.com/
                  <div className="ProfileInputLine">
                    <Form.Control
                      type="text"
                      value={igUser}
                      onChange={(e) => setIgUser(e.target.value.toLocaleLowerCase())}
                    />
                  </div>
                </div>
                <div className="ProfileSocialsRow">
                  <TiktokIcon width="20px" height="20px" />
                  tiktok.com/@
                  <div className="ProfileInputLine">
                    <Form.Control
                      type="text"
                      value={tiktokUser}
                      onChange={(e) => setTiktokUser(e.target.value.toLocaleLowerCase())}
                    />
                  </div>
                </div>
                {data?.ig_user && data?.tiktok_user ?
                  <div className="ProfileEditBannerComplete">
                    <div className="ProfileEditBannerIcon">
                      <DiamondIcon color="#696969" />
                    </div>
                    ¿Te gustaría convertirte en creadora de contenido? Ingresa tus datos para aportar contenido de forma activa en Cranberry Chic.
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>

          <button type="submit" className="RecentPostButton">
            Guardar cambios
          </button>
        </div>

      </Form>
      {show && <NewAvatar  preFoto={data?.profile_picture} setShow ={setShow} image = {image} setImage ={setImage} newImage={newImage} setNewImage={setNewImage} setDeletedImage={setDeletedImage} deletedImage={deletedImage} />}
    </div>
  );
}
