import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from "../../assets/SVG/CloseIcon"

const ModalLogin = ({ setShow }) => {
    let navigate = useNavigate()

    return (
      <div
        className='ModalCenteredLayout'
        onClick={() => setShow(false)}
      >
        <div
          className='ModalCenteredBox'
          onClick={(e) => e.stopPropagation()}
        >
            <button className='ModalCloseButton' onClick={() => setShow(false) }>
            <CloseIcon width='20px' height='20px' />
            </button>
            <div className='ModalTitle'>Contenido Exclusivo</div>
            <div className='ModalBody'> Para acceder a esta función necesitas iniciar sesión. 
              Aquí podrás descubrir todas las funciones exclusivas que tenemos para ti. 
              ¡Inicia sesión ahora!
            </div>
          <button className="ModalButton" onClick={() => {setShow(false); navigate("../../login")}}> Ingresa aquí </button>
          <button className='ModalSecondaryButton'  
            onClick={() => {setShow(false); navigate("../../register")}}
          > Registrate </button>
        </div>
      </div>
    );
  };


  export default ModalLogin;